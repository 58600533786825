<template>
  <div class="cart-item">
    <div class="wrapper-title">
      <span class="title">{{ product.title }}</span>
      <span class="quantity">({{ product.quantity }} шт)</span>
    </div>
    <div class="price-control">
      <button
        class="btn-minus circle-plus minus"
        :disabled="product.quantity <= 1"
        @click="decreaseQuantity"
      /> <!-- Self-closing button -->
      <p class="price fs--medium fw--bold">
        <span class="number">{{ product.price * product.quantity }}</span>
        <span class="currency gray-text">₽</span>
      </p>
      <button
        class="btn-plus circle-plus"
        @click="increaseQuantity"
      /> <!-- Self-closing button -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartItem',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    increaseQuantity() {
      this.$emit('update-quantity', this.product.quantity + 1);
    },
    decreaseQuantity() {
        this.$emit('update-quantity', this.product.quantity - 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wrapper-title {
    margin-right: 6px;
    .title {
      margin-right: 12px;
      @include max-w-xs {
        margin-right: 6px;
      }
    }
    .quantity {
      white-space: nowrap;
    }
  }
  .price-control {
    display: flex;
    align-items: center;
    .price {
      margin: 0 14px;
      @include max-w-xs {
        margin: 0 7px;
      }
      .number {
        margin-right: 8px;
        @include max-w-xs {
          margin-right: 4px;
        }
      }
      .currency {
        color: $gray;
      }
    }
    .btn-minus,
    .btn-plus {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    .btn-minus[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
