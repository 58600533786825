<template>
  <modal
    :name="modalName"
    height="auto"
    :max-width="582"
    :adaptive="true"
    class="modal"
    @opened="opened"
    @before-close="beforeClose"
  >
    <button class="btn-close" :class="{ active : isOpened }" @click="$modal.hide(modalName)" />
    <div class="wrapper-content">
      <p class="modal-title fs--medium mb-xs">
        Ваша корзина
      </p>
      <p class="mb-l description">
        Все добавленные продукты находятся здесь
      </p>
      <div v-if="cartProducts.length" class="wrapper-products">
        <p class="fs--medium modal-title">
          Выбранные продукты:
        </p>
        <div class="divider" />
        <cart-item
          v-for="item in cartProducts"
          :key="item.id"
          class="mb-m"
          :product="item"
          @update-quantity="updateQuantity(item.id, $event)"
        />
        <div class="divider" />
        <div class="total-price mb-l">
          <p class="total">
            Итого:
          </p>
          <p class="fw--bold fs--medium">
            <span class="number">{{ totalPrice }}</span>
            <span class="currency">₽</span>
          </p>
        </div>
        <app-button
          class="btn-purchase mb-l"
          title="Оформить заказ"
          description="Переход к вводу данных и оплате"
          primary
          to="/ordering"
        >
          <icon-arrow-right class="icon" />
        </app-button>
        <app-link to="/#products">
          Продолжить покупки
        </app-link>
      </div>
      <div v-else>
        <p>Корзина пуста</p>
      </div>
    </div>
  </modal>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppLink from '@/components/common/AppLink'
import CartItem from '@/components/CartItem'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'
import { mapState, mapActions } from 'vuex' // Import mapState and mapActions

export default {
  name: 'ModalCart',
  components: {
    AppButton,
    AppLink,
    CartItem,
    IconArrowRight
  },
  data() {
    return {
      modalName: 'modal-cart',
      isOpened: false,
    }
  },
  computed: {
    ...mapState('cart', {
      cartProducts: state => state.products, // Get cart products from Vuex
      totalPrice() {
        return this.cartProducts.reduce((total, product) => {
          return total + product.price * product.quantity;
        }, 0);
      }
    })
  },
  methods: {
    ...mapActions('cart', ['updateQuantity']), // Map actions from Vuex
    opened() {
      this.isOpened = true;
    },
    beforeClose() {
      this.isOpened = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .total-price {
    display: flex;
    justify-content: space-between;
    .price {
      .number {
        margin-right: 8px;
      }
      .currency {
        color: $gray;
      }
    }
  }
  .btn-purchase {
    .icon {
      width: 24px;
      height: 24px;
      @include max-w-lg {
        width: 20px;
        height: 20px;
      }
      @include max-w-xs {
        width: 16px;
        height: 16px;
      }
      path {
        stroke: $white;
      }
    }
  }
</style>
