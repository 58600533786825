<template>
  <header class="s-header">
    <div class="container">
      <div class="wrapper">
        <button class="burger" @click="openMobileMenu">
          <span />
          <span />
          <span />
        </button>
        <router-link class="logo-link" to="/#home">
          <logo-svg class="logo-img" />
        </router-link>
        <nav class="site-navigation">
          <ul class="navigation-list">
            <li class="navigation-item">
              <router-link class="navigation-link" to="/#about">
                <div class="wrapper-link">
                  <icon-info view-box="0 0 28 29" class="icon" />
                  <span>О компании</span>
                </div>
              </router-link>
            </li>
            <li class="navigation-item">
              <router-link class="navigation-link" to="/login">
                <div class="wrapper-link">
                  <icon-user class="icon icon-user" />
                  <span>Вход для партнеров</span>
                </div>
              </router-link>
            </li>
            <li class="navigation-item">
              <router-link class="navigation-link" to="/#products">
                <div class="wrapper-link">
                  <icon-product class="icon" />
                  <span>Продукты</span>
                </div>
              </router-link>
            </li>
            <li class="navigation-item">
              <router-link class="navigation-link" to="/#footer">
                <div class="wrapper-link">
                  <icon-question class="icon" />
                  <span>Как это работает</span>
                </div>
              </router-link>
            </li>
            <!--
            <li class="navigation-item">
              <router-link class="navigation-link" to="/activation#hero">
                <div class="wrapper-link">
                  <icon-debug class="icon" />
                  <span>Активировать</span>
                </div>
              </router-link>
            </li>
            <li class="navigation-item">
              <router-link class="navigation-link" to="/renewal#hero">
                <div class="wrapper-link">
                  <icon-clock class="icon icon-clock" />
                  <span>Продлить</span>
                </div>
              </router-link>
            </li>
            -->
          </ul>
        </nav>
        <!--
        <div class="wrapper-user">
          <router-link class="user-link" to="/auth">
            <icon-user class="icon-user" />
          </router-link>
          <button class="wrapper-cart" @click="$modal.show('modal-cart')">
            <p class="cart-number">
              2
            </p>
            <icon-cart class="icon-cart" />
          </button>
          <app-switch-locale />
        </div>
        -->
      </div>
    </div>
  </header>
</template>

<script>
import LogoSvg from '@/assets/img/logo.svg'
//import AppSwitchLocale from '@/components/common/AppSwitchLocale'
//import IconCart from '@/assets/img/icons/cart.svg'
import IconInfo from '@/assets/img/icons/info.svg'
import IconProduct from '@/assets/img/icons/product.svg'
import IconQuestion from '@/assets/img/icons/question.svg'
//import IconDebug from '@/assets/img/icons/debug.svg'
//import IconClock from '@/assets/img/icons/clock.svg'
import IconUser from '@/assets/img/icons/user.svg'

export default {
  name: 'AppHeader',
  components: {
    LogoSvg,
    //AppSwitchLocale,
    //IconCart,
    IconInfo,
    IconProduct,
    IconQuestion,
    //IconDebug,
    //IconClock,
    IconUser
  },
  methods: {
    openMobileMenu () {
      this.$store.dispatch('setVisibleDrawer')
    }
  }
}
</script>

<style lang="scss" scoped>
  .s-header {
    backdrop-filter: saturate(100%) blur(12px);
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    @include max-w-lg {
      height: 100px;
    }
    @include max-w-xs {
      height: 64px;
    }
    .container {
      max-width: none;
      padding-top: 0;
      display: flex;
      align-items: center;
      height: 44px;
      @include max-w-xs {
        height: 32px;
      }
      .wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
  .burger {
    margin-right: 10px;
    display: none;
    @include max-w-lg {
      display: block;
    }
  }
  .logo-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    .logo-img {
      width: 92px;
      height: 42px;
      @include max-w-xs {
        width: 52px;
        height: 24px;
      }
    }
  }
  .site-navigation {
    @include max-w-lg {
      display: none;
    }
    margin: 0 auto;
    width: 70%;
    .navigation-list {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: $box-shadow-base;
      border-radius: $border-radius-large;
      background: $white;
      list-style: none;
      .navigation-item {
        padding: 0 22px;
        border-right: 2px solid $gray;
        &:last-child {
          border: none;
        }
        .wrapper-link {
          white-space: nowrap;
          display: flex;
          align-items: center;
          .icon {
            margin-right: 8px;
          }
        }
      }
    }
  }
  .wrapper-user {
    display: flex;
    align-items: center;
    @include max-w-lg {
      margin-left: auto;
    }
    .user-link {
      width: 24px;
      height: 24px;
      margin-right: 18px;
    }
    .wrapper-cart {
      margin-right: 18px;
      display: flex;
      align-items: center;
      .cart-number {
        margin-right: 4px;
        border-radius: $border-radius-base;
        box-shadow: $box-shadow-base;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }
    }
  }
</style>
